<template>
  <div class="d-flex flex-column flex-root">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #f2c98a"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="/media/logos/logo-letter-1.png"
              class="max-h-70px"
              alt="Logo"
            />
          </a>
        </div>
        <div
          id="footer-login"
          class="aside-img flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center d-none d-md-flex"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Bienvenido!
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Correo</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Contraseña</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >¿ Olvidó su contraseña ?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  v-on:click="onSubmitLogin"
                >
                  Ingresar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Registrarse
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Coloca los detalles para registrar tu cuenta
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Nombre Completo"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Correo"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirma tu contraseña"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  Estoy de acuerdo con los
                  <a href="#" class="ml-2">términos y condiciones</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  ¿ Olvidó su contraseña ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Coloca tu correo para restablecer su contraseña
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Correo"
                  name="email_forgot"
                  ref="email_forgot"
                  v-model="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  v-on:click="onSubmitForgot"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
          <!--begin::Password-->
          <div class="login-form login-password">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_password_form"
              ref="kt_login_password_form"
              @submit.stop.prevent="onSubmitPassword()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  ¿ Olvidó su contraseña ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Coloca tu nueva contraseña
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Contraseña"
                  name="passwordn"
                  ref="passwordn"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirma tu contraseña"
                  name="passwordc"
                  ref="passwordc"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_password_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_password_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Password-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  FORGOT,
  GET_USER_TOKEN,
  PASSWORD,
} from "@/core/services/store/auth.module";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import Swal from "sweetalert2";
import Loader from "@/view/content/Loader.vue";

export default {
  name: "login-1",
  components: {
    Loader,
  },
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      email: "",
      password: "",
      id: null,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser", "layoutConfig"]),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
  async mounted() {
    let oVue = this;
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    const password_form = KTUtil.getById("kt_login_password_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Correo es requerido",
            },
            emailAddress: {
              message: "El correo no es válido",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Contraseña es requerida",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "El nombre completo es requerido",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "El correo es requerido",
            },
            emailAddress: {
              message: "El correo no es válido",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "La contraseña es requerida",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirmar contraseña es requerida",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Las contraseñas no coinciden",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "Tiene que aceptar los términos y condiciones",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email_forgot: {
          validators: {
            notEmpty: {
              message: "El correo es requerido",
            },
            emailAddress: {
              message: "El correo no es válido",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv3 = formValidation(password_form, {
      fields: {
        passwordn: {
          validators: {
            notEmpty: {
              message: "La contraseña es requerida",
            },
          },
        },
        passwordc: {
          validators: {
            notEmpty: {
              message: "Confirmar contraseña es requerida",
            },
            identical: {
              compare: function () {
                return password_form.querySelector('[name="password"]').value;
              },
              message: "Las contraseñas no coinciden",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    if (this.$route.params.token) {
      const aGetUserTokenResponse = await oVue.getUsuarioToken(
        this.$route.params.token
      );
      if (
        aGetUserTokenResponse.result &&
        aGetUserTokenResponse.data.length > 0
      ) {
        oVue.id = aGetUserTokenResponse.data[0].idUsuario;
        oVue.showForm("password");
      } else {
        Swal.fire({
          title: "",
          text: "El enlace ha caducado!",
          icon: "error",
          heightAuto: false,
        });
        oVue.showForm("signin");
      }
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {
      var oVue = this;
      this.fv.validate();

      var email = oVue.email;
      var password = oVue.password;
      if (!oVue.validEmail(email) || !password) {
        Swal.fire({
          title: "",
          text: "Por favor, ingresar los datos correctos!",
          icon: "error",
          heightAuto: false,
        });
        return;
      }
      KTUtil.setLoading();
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, { username: email, password })
        .then((response) => {
          if (response.result) {
            const usuarioID = oVue.currentUser.usuarioID;
            const nombres = oVue.currentUser.nombres;
            const apellido_paterno = oVue.currentUser.apellidoPaterno;
            const apellido_materno = oVue.currentUser.apellidoMaterno;
            const phone = oVue.currentUser.telefono;
            const email = oVue.currentUser.correo;
            const rut = oVue.currentUser.rut;
            const perfil = oVue.currentUser.perfil;
            const perfil_id = Number(oVue.currentUser.perfilID);
            const mandanteID = oVue.currentUser.mandanteID;
            const mandante = oVue.currentUser.mandante;
            this.$store.dispatch(UPDATE_PERSONAL_INFO, {
              usuarioID,
              nombres,
              apellido_paterno,
              apellido_materno,
              phone,
              photo: "",
              email,
              rut,
              perfil,
              perfil_id,
              mandanteID,
              mandante,
            });
            this.$router.push({ name: "dashboard" });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              heightAuto: false,
            });
            KTUtil.removeLoading();
          }
        })
        .catch(() => {});

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    onSubmitRegister() {
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        const email = this.$refs.remail.value;
        const password = this.$refs.rpassword.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send register request
          this.$store
            .dispatch(REGISTER, {
              email: email,
              password: password,
            })
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Por favor, ingresar los datos correctos!",
          icon: "error",
          heightAuto: false,
        });
      });
    },
    onSubmitForgot() {
      var oVue = this;
      this.fv2.validate();
      var email = this.$refs.email_forgot.value;
      if (!oVue.validEmail(email)) {
        Swal.fire({
          title: "",
          text: "Por favor, ingresar los datos correctos!",
          icon: "error",
          heightAuto: false,
        });
        return;
      }
      KTUtil.setLoading();
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(FORGOT, { username: email, app: "BO" })
        .then((response) => {
          if (response.result) {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "success",

              heightAuto: false,
            });
            oVue.showForm("signin");
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              heightAuto: false,
            });
          }
          KTUtil.removeLoading();
        })
        .catch(() => {});

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    async getUsuarioToken(sToken) {
      KTUtil.setLoading();
      let oVue = this;
      const aGetUserTokenResponse = await oVue.$store.dispatch(GET_USER_TOKEN, {
        token: sToken,
      });
      KTUtil.removeLoading();

      return aGetUserTokenResponse;
    },
    onSubmitPassword() {
      let oVue = this;
      this.fv3.validate();

      this.fv3.on("core.form.valid", () => {
        KTUtil.setLoading();
        const password = this.$refs.passwordc.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_password_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        this.$store
          .dispatch(PASSWORD, {
            id: oVue.id,
            password: password,
          })
          .then((response) => {
            if (response.result) {
              Swal.fire({
                title: "",
                text: "Contraseña cambiada exitosamente",
                icon: "success",
                heightAuto: false,
              });
              oVue.showForm("signin");
            } else {
              Swal.fire({
                title: "",
                text: response.error,
                icon: "error",
                heightAuto: false,
              });
            }
            KTUtil.removeLoading();
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      });

      this.fv3.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Por favor, ingresar los datos correctos!",
          icon: "error",
          heightAuto: false,
        });
      });
    },
    validEmail: function (email) {
      //eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  watch: {
    email(newValue, oldValue) {
      const regex = /^[a-zA-Z0-9@._-]*$/;
      if (!regex.test(newValue)) {
        this.email = oldValue;
      }
    },
  },
};
</script>
